<template>
  <div>
    <heading-separator :title="title" :div="div"></heading-separator>
  </div>
</template>

<script>
import HeadingSeparator from "./HeadingSeparator.vue";

export default {
  name: "FirstVaultHeading",
  components: { HeadingSeparator },
  data() {
    return {
      title: "FirstVault - Enterprise Digital Vault",
      div: "<div><br> <p class='white--text'> FirstVault is an enterprise level security-first digital privacy vault which provides employees of an organization a platform to store and share their sensitive documents securely along with other sensitive data such as Personally Identifiable Information (PII), login credentials, important documents and secrets using a 'Zero-Trust, Zero-Knowledge' security architecture. </p></div>",
    };
  },
};
</script>
